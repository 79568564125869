import {
  ISearchParams,
  TGoodsOption,
  TGoodsOrderCancelFormData,
  TGoodsOrderExchangeFormData,
  TPaymentOrderFormData,
} from 'src/@types';
import { ICoupon, IGoodsPayment, IOrder, IOrderGoods } from 'src/@types/apiResponse';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/common/v1/order';

interface CheckOrderResponseData {
  totPayment: number;
  continueYn: boolean;
  alertYn: boolean;
  alertMsg: null | string;
}
/**
 * 주문 상품 확인 요청
 */
export const checkOrder = async (payload: TGoodsOption[]) => {
  try {
    const url = `${SUB_URL}/goods/check`;
    const response = await instance.post<TApiResponseData<CheckOrderResponseData>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 주문 상품 결제 금액 조회
 */

export const getGoodsPayment = async (payload: {
  goodsList: TGoodsOption[];
  cpnList?: ICoupon[];
}) => {
  try {
    const url = `${SUB_URL}/amt/info`;
    const response = await instance.post<TApiResponseData<IGoodsPayment>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 주문 등록
 */
export const paymentOrder = async (payload: TPaymentOrderFormData) => {
  try {
    const url = `${SUB_URL}`;
    const response = await instance.post<TApiResponseData<IOrder>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 주문 내역 조회
 */
export const getOrders = async (payload: ISearchParams) => {
  try {
    const url = `${SUB_URL}`;
    const response = await instance.get<TApiResponseData<IOrder[]>>(url, {
      params: payload,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 주문 정보 상세 조회
 */
export const getOrder = async (orderNo: string) => {
  try {
    const url = `${SUB_URL}/${orderNo}`;
    const response = await instance.get<TApiResponseData<IOrder>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 주문 상품 상세 조회
 */
export const getOrderGoods = async (orderNo: string, orderGoodsNo: string) => {
  try {
    const url = `${SUB_URL}/${orderNo}/orderGoods/${orderGoodsNo}`;
    const response = await instance.get<TApiResponseData<IOrderGoods>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 주문 상품 취소 요청
 */
export const orderGoodsCancel = async (orderNo: string, data: TGoodsOrderCancelFormData) => {
  try {
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'orderGoodsNoList') {
        (value as string[]).forEach((file) => {
          form.append('orderGoodsNoList', file);
        });
      } else if (key === 'uploadFiles') {
        (value as File[]).forEach((file) => {
          form.append('uploadFiles', file);
        });
      } else {
        form.append(key, String(value));
      }
    });
    const url = `${SUB_URL}/${orderNo}/cancel`;
    const response = await instance.put<TApiResponseData<IOrder>>(url, form);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 주문 싱품 교환 요청
 */
export const orderGoodsExchange = async (orderNo: string, data: TGoodsOrderExchangeFormData) => {
  try {
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'orderGoodsNoList') {
        (value as string[]).forEach((file) => {
          form.append('orderGoodsNoList', file);
        });
      } else if (key === 'uploadFiles') {
        (value as File[]).forEach((file) => {
          form.append('uploadFiles', file);
        });
      } else {
        form.append(key, String(value));
      }
    });
    const url = `${SUB_URL}/${orderNo}/exchange`;
    const response = await instance.put<TApiResponseData<IOrder>>(url, form);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 구매 확정
 */
export const confirmPurchase = async (orderGoodsNo: string) => {
  try {
    const url = `/common/v1/orderGoods/${orderGoodsNo}/purchase/confirm`;
    const response = await instance.put<TApiResponseData<IOrderGoods>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};
