import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.json';
import ko from './ko/translation.json';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ko: {
        translation: ko,
      },
    },
    fallbackLng: 'en', // 지원하지 않는 언어일 때 사용할 기본 언어
    debug: true, // 개발 시 디버깅을 위한 옵션
    interpolation: {
      escapeValue: false, // escape 방지("/" 등 특수문자)
    },
  });
