import { reaction } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { TGoodsOption } from 'src/@types';
import { INIT_PAYMENT } from 'src/assets/static/constants';
import { cartApi } from 'src/services';

import { CodeModel } from '../common-code/CommonCode';
import { withRootStore } from '../extensions/withRootStore';
import { withSetPropAction } from '../extensions/withSetPropAction';

const GoodsModel = types
  .model()
  .props({
    regDt: types.number,
    updDt: types.number,
    goodsSid: types.number,
    thumbnlPath: types.array(types.string),
    goodsNm: types.string,
    goodsSummary: types.maybeNull(types.string),
    goodsDescr: types.maybeNull(types.string),
    containerGoodsYn: types.boolean, // 컨테이너 상품 여부
    price: types.number, // 정가 (부가세 포함)
    dispDscntRate: types.number, // 할인율
    goodsAmt: types.number, // 상품금액 (할인된 금액)
    currencyCd: CodeModel,
    inCartYn: types.boolean, // 장바구니 담은 여부
    cnt: types.number, // 수량
    selected: types.maybeNull(types.optional(types.boolean, true)),
  })
  .actions((self) => ({
    setCnt(cnt: number) {
      self.cnt = cnt; // cnt를 직접 업데이트하는 액션
    },
  }))
  .actions((self) => ({
    toggleSelected() {
      self.selected = !self.selected;
    },
    async updateCnt(cnt: number) {
      try {
        const result = await cartApi.updateCartGoods({
          goodsSid: self.goodsSid,
          cnt,
        });
        if (result?.success) {
          // update cnt
          self.setCnt(cnt);
        }
      } catch (error) {
        console.error(error);
      }
    },
  }));

const PaymentModel = types.model({
  totPriceAmt: types.number, // 총 합계
  totGoodsAmt: types.number, // 상품금액 합계
  totDscntAmt: types.number, // 할인금액 합계
  totPaymentAmt: types.number, // 결제금액 합계
  currencyCd: types.maybeNull(CodeModel),
});

/**
 * # CartStore
 */
export const CartStoreModel = types
  .model('CartStore')
  .props({
    cartList: types.optional(types.array(GoodsModel), []),
    totalAmount: types.optional(types.number, 0),
    payment: types.optional(PaymentModel, INIT_PAYMENT),
  })
  .views((self) => ({
    getSelectedCartList() {
      return self.cartList.filter((goods) => goods.selected);
    },
    getSelectedGoodsPayment() {
      return self.cartList
        .filter((cart) => cart.selected)
        .reduce(
          (acc, goods) => {
            acc.totPriceAmt += goods.price * goods.cnt;
            acc.totGoodsAmt += goods.goodsAmt * goods.cnt;
            acc.totDscntAmt += (goods.price - goods.goodsAmt) * goods.cnt;
            acc.totPaymentAmt += goods.goodsAmt * goods.cnt;
            return acc;
          },
          { ...INIT_PAYMENT },
        );
    },
  }))
  .extend(withRootStore)
  .actions(withSetPropAction)
  .actions((self) => ({
    allGoodsSelectedChange(selected: boolean) {
      self.cartList.forEach((goods) => {
        goods.selected = selected;
      });
    },
  }))
  .actions((self) => ({
    async getCart() {
      try {
        const result = await cartApi.getCart();
        if (result) {
          const updatedCartList = result.data.goods.cartList.map((newGoods) => {
            // 기존 cartList에 같은 goodsSid가 있는지 확인
            const existingGoods = self.cartList.find(
              (existingGoods) => existingGoods.goodsSid === newGoods.goodsSid,
            );

            // 만약 같은 상품이 있으면 selected 값을 유지, 그렇지 않으면 새로운 값을 그대로 사용
            return {
              ...newGoods,
              selected: existingGoods ? existingGoods.selected : true, // 기존 selected 값 유지
            };
          });

          self.setProps({
            cartList: updatedCartList,
            payment: result.data.payment,
            totalAmount: result.data.goods.pagination.totalElements,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  }))
  .actions((self) => ({
    async addCartGoods(payload: TGoodsOption) {
      try {
        const result = await cartApi.addCartGoods(payload);
        if (result?.success) {
          // 장바구니에 상품 추가
          self.getCart();
        }

        return result;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteCartGoods(goodsSidList: number[]) {
      try {
        const result = await cartApi.deleteCartGoods(goodsSidList);
        if (result?.success) {
          // 선택된 상품 list에서 제거
          self.setProps({
            cartList: self.cartList.filter((goods) => !goodsSidList.includes(goods.goodsSid)),
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // isAuthenticated가 true이면 새로고침 시 cartList를 가져옴
      reaction(
        () => self.rootStore.authStore.isAuthenticated, // isAuthenticated 값의 변화를 추적
        () => {
          if (self.rootStore.authStore.isAuthenticated) {
            self.getCart();
          }
        },
      );
    },
  }));

type CartStoreType = Instance<typeof CartStoreModel>;
export interface ICartStore extends CartStoreType {}
type CartStoreSnapshotType = SnapshotOut<typeof CartStoreModel>;
export interface CartStoreSnapshot extends CartStoreSnapshotType {}
