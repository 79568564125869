import { AuthResponse } from 'src/@types/apiResponse';
import { IUserSnapshot } from 'src/models/user/User';
import { jwtDecode } from 'src/utils/jwtHelper';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/common/v1/user';

/**
 * refresh token으로 accessToken 갱신
 */
export const refreshAccessToken = async (refreshToken: string) => {
  try {
    const url = `${SUB_URL}/login/refresh`;
    const payload = {
      refreshToken,
    };
    const response = await instance.post<TApiResponseData<AuthResponse>>(url, payload);

    // api 호출 성공 시
    if (response.data.resultCode === 'S') {
      // 로그인 성공 시
      return {
        success: true,
        message: '로그인 성공',
        access_token: response.data.data?.access_token,
        refresh_token: response.data.data?.refresh_token,
      };
    }
    // api 호출 실패 시
    throw new Error(response.data.errorMessage || '토큰 갱신 실패');
  } catch (error) {
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 로그인
 */
export const login = async (loginId: string, pwd: string) => {
  try {
    const url = `${SUB_URL}/login`;
    const payload = {
      loginId,
      pwd,
    };
    const response = await instance.post<TApiResponseData<AuthResponse>>(url, payload);

    // api 호출 성공 시
    if (response.data.resultCode === 'S' && response.data.data) {
      const { access_token, refresh_token } = response.data.data;
      // 로그인 성공 시
      return {
        success: true,
        message: '로그인 성공',
        access_token,
        refresh_token,
      };
    }
    return { success: false, message: response.data.errorMessage };
  } catch (error) {
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

// LOGIN_SNS
export const loginSns = async (data: any) => {
  const json: any = jwtDecode(data.access_token.toString());
  const { access_token, refresh_token } = data;
  if (json) {
    return {
      success: true,
      message: '로그인 성공',
      access_token,
      refresh_token,
    };
    // setSession(data.access_token);
    // setRefreshSession(data.refresh_token);

    // const response = await axios.get(REACT_APP_API_URL + '/common/v1/user/profile');
    // const user = response.data.data as IUserSnapshot;
    // const res = await getProfile();
    // const user = res?.userInfo;

    // return {
    //   ...res,
    //   success: true,
    //   message: '로그인 성공',
    //   access_token: data.access_token,
    //   refresh_token: data.refresh_token,
    //   user,
    // };

    // return response.data;
    // sendReactNativeMessage({
    //   type: 'saveUserToken',
    //   payload: {
    //     rememberId: localStorage.getItem('rememberId'),
    //     rememberMe: localStorage.getItem('rememberMe'),
    //     accessToken: data.access_token,
    //     refreshToken: data.refresh_token,
    //   },
    // });

    // dispatch({
    //   type: Types.LOGIN_SNS,
    //   payload: {
    //     user: {
    //       scope: json.scope,
    //       sid: json.sid,
    //       userNm: json.userNm,
    //       nationCd: json.nationCd,
    //       acntSttsCd: json.acntSttsCd,
    //       email_verified: json.email_verified,
    //       loginId: json.loginId,
    //       userSid: json.userSid,
    //       nickNm: json.nickNm || json.nickname,
    //       profileImgPath: user.profileImgPath,
    //       langCd: json.langCd,
    //       preferred_username: json.preferred_username,
    //       loginType: user.loginType,
    //       snsAppleYn: user.snsAppleYn,
    //       snsFacebookYn: user.snsFacebookYn,
    //       snsGoogleYn: user.snsGoogleYn,
    //       snsKakaoYn: user.snsKakaoYn,
    //       snsNaverYn: user.snsNaverYn,
    //       phoneNo: user.phoneNo,
    //       dtcAgrmntYn: user.dtcAgrmntYn,
    //     },
    //   },
    // });
  } else {
    return { success: false, message: data.errorMessage };
  }
  //  else {
  //   return { success: false, message: json.data.errorMessage };
  // }
};

/**
 * 로그아웃
 */
export const logout = async (refreshToken: string) => {
  try {
    const url = `${SUB_URL}/logout`;

    const response = await instance.post<TApiResponseData<any>>(url, {
      refreshToken: refreshToken,
    });

    // 성공 시 로그인
    if (response.data.resultCode === 'S') {
      return {
        data: {
          success: true,
          message: '로그아웃 성공',
        },
      };
    }

    // api 호출 실패 시
    throw new Error('로그아웃 실패');
  } catch (error) {
    console.error(error);
    return {
      data: {
        success: false,
        message: '로그아웃 실패',
      },
    };
  }
};

/**
 * 사용자 정보 조회
 */
export const getProfile = async () => {
  try {
    const url = `${SUB_URL}/profile`;
    // token이 있는 경우 header에 Authorization 추가
    const response = await instance.get<TApiResponseData<IUserSnapshot>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        userInfo: response.data.data,
      };
    }

    // api 호출 실패 시
    throw new Error('사용자 정보 조회 실패');
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
  }
};

/**
 * 아이디 찾기
 */
export const findId = async (data: any) => {
  try {
    const url = `${SUB_URL}/find/loginid/verification`;
    const response = await instance.post<TApiResponseData<any>>(url, data);
    if (response.data.resultCode === 'S') {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
  }
};

/**
 * 프로필정보 수정
 */
export const updateProfile = async (data: {
  nickNm?: string;
  profileImgPath?: string;
  uploadFile?: File;
  email?: string;
}) => {
  try {
    const url = `${SUB_URL}/profile`;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await instance.put<TApiResponseData<IUserSnapshot>>(url, data, config);

    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        userInfo: response.data.data,
      };
    } else {
      return {
        userInfo: null,
        data: response.data,
      };
    }

    // api 호출 실패 시
    // throw new Error('프로필정보 수정 실패');
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
  }
};

/**
 * 언어정보 수정
 */
export const updateLang = async (data: { refreshToken: string; langCd: string }) => {
  try {
    const url = `${SUB_URL}/lang`;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await instance.put<TApiResponseData<AuthResponse>>(url, data, config);

    //   if (response.data.resultCode === 'S' && response.data.data) {
    //     return {
    //       userInfo: response.data.data,
    //     };
    //   } else {
    //     return {
    //       userInfo: null,
    //       data: response.data,
    //     };
    //   }

    //   // api 호출 실패 시
    //   // throw new Error('프로필정보 수정 실패');
    // } catch (error) {
    //   if (error instanceof Error) {
    //     console.error(error.message);
    //   } else {
    //     console.error('Unknown error:', error);
    //   }
    // }

    // api 호출 성공 시
    if (response.data.resultCode === 'S' && response.data.data) {
      const { access_token, refresh_token } = response.data.data;
      // 로그인 성공 시
      return {
        success: true,
        message: '언어변경 성공',
        access_token,
        refresh_token,
      };
    }
    return { success: false, message: response.data.errorMessage };
  } catch (error) {
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 비밀번호 변경(로그인 후)
 */
export const changePw = async (data: { oldPwd: string; pwd: string; confirmPwd: string }) => {
  try {
    const url = `${SUB_URL}/change/pwd`;
    const response = await instance.put<TApiResponseData<any>>(url, data);

    if (response.data.resultCode === 'S') {
      return {
        data: {
          success: true,
          message: '변경 성공',
        },
      };
    } else {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
  }
};

/**
 * 회원가입
 */
export const signup = async (data: any) => {
  try {
    const url = `${SUB_URL}/signup`;
    const response = await instance.post<TApiResponseData<any>>(url, data);
    // if (response.data.resultCode === 'S' && response.data.data) {
    return response.data;
    // }
    // api 호출 실패 시
    // throw new Error('가입 실패');
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
  }
};

/**
 * 회원통합
 */
export const integration = async (data: any) => {
  try {
    const url = `${SUB_URL}/integration`;
    const response = await instance.post<TApiResponseData<any>>(url, data);
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    } else {
      return response.data;
    }
    // api 호출 실패 시
    throw new Error('계정통합 실패');
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
  }
};

/**
 * 회원탈퇴
 */
export const withdrawal = async (data: any) => {
  try {
    const url = `${SUB_URL}/withdrawal?groupCdSid=
    ${data.groupCdSid}&refreshToken=${data.refreshToken}
    ${data.wthdReason ? '&wthdReason=' + data.wthdReason : ''}`;
    const response = await instance.delete<TApiResponseData<any>>(url);
    if (response.data.resultCode === 'S') {
      return {
        data: {
          ...response.data,
          success: true,
          message: '탈퇴 성공',
        },
      };
    } else {
      return {
        data: { ...response.data, success: false, message: '탈퇴 실패' },
      };
    }

    // api 호출 실패 시
    throw new Error('탈퇴 실패');
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);

      return {
        data: {
          success: false,
          message: '탈퇴 실패',
        },
      };
    }
  }
};

/**
 * 로그인 ID 중복 확인
 */
export const idDuplicateCheck = async (loginId: string) => {
  try {
    const url = `${SUB_URL}/exist/loginid?loginId=${loginId}`;
    const response = await instance.get<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S') {
      return {
        data: {
          success: true,
          message: '사용가능한 아이디',
        },
      };
    } else {
      return response.data;
    }

    // api 호출 실패 시
    throw new Error('아이디 중복');
  } catch (error) {
    console.error(error);
    return {
      data: {
        success: false,
        message: '확인실패',
      },
    };
  }
};

/**
 * 계정 연결 해제 공통
 */
export const snsUnlink = async (snsTypeCd: number) => {
  try {
    const url = `${SUB_URL}/unlink/sns?snsTypeCd=${snsTypeCd}`;
    const response = await instance.delete<TApiResponseData<any>>(url);

    // if (response.data.resultCode === 'S') {
    //   return {
    //     data: {
    //       success: true,
    //       message: '연결해제',
    //     },
    //   };
    // } else {
    return response.data;
    // }
  } catch (error) {
    console.error(error);
    return {
      data: {
        success: false,
        message: '연결해제 실패',
      },
    };
  }
};
