import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/i18n/config';

import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MotionLazyContainer } from 'src/components/animate';

import { RootStore, RootStoreProvider, setupRootStore } from './models';
import router from './routes';
import ThemeProvider from './theme';

function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  useEffect(() => {
    // setup root store
    setupRootStore().then((store) => {
      setRootStore(store);
    });
  }, []);

  if (!rootStore) {
    return <div>Loading...</div>;
  }

  return (
    <RootStoreProvider value={rootStore}>
      <HelmetProvider>
        <Helmet>
          <title>{process.env.REACT_APP_APP_NAME}</title>
        </Helmet>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MotionLazyContainer>
              <RouterProvider router={router} />
            </MotionLazyContainer>
          </LocalizationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </RootStoreProvider>
  );
}

export default App;
