import i18next from 'i18next';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { authApi } from 'src/services';

import { withRootStore } from '../extensions/withRootStore';
import { withSetPropAction } from '../extensions/withSetPropAction';
import { UserModel } from '../user/User';

/**
 * # AuthStore
 */
export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    isAuthenticated: types.optional(types.boolean, false),
    isInitialized: types.optional(types.boolean, false),
    user: types.optional(types.maybeNull(UserModel), null),
  })
  .extend(withRootStore)
  .actions(withSetPropAction)
  .actions((self) => ({
    /**
     * 액세스 토큰 조회
     */
    getAccessToken() {
      return localStorage.getItem('accessToken');
    },
    setAccessToken(token: string) {
      localStorage.setItem('accessToken', token);
    },
    getRefreshToken() {
      return localStorage.getItem('refreshToken');
    },
    setRefreshToken(token: string) {
      localStorage.setItem('refreshToken', token);
    },
  }))
  .actions((self) => ({
    /**
     * auth 초기화
     */
    initAuth() {
      self.setProp('isAuthenticated', false);
      self.setProp('user', null);
      self.setAccessToken('');
      self.setRefreshToken('');
      localStorage.removeItem('i18nextLng');
    },
  }))
  .actions((self) => {
    /**
     * 로그인
     * @param loginId 로그인 아이디
     * @param pwd 비밀번호
     */
    const login = async (loginId: string, pwd: string) => {
      try {
        const result = await authApi.login(loginId, pwd);
        // accessToken이 존재하면 local storage에 저장한다.
        if (result.success && result.access_token) {
          self.setAccessToken(result.access_token);
          self.setRefreshToken(result.refresh_token);
          self.setProp('isAuthenticated', true);
        } else {
          self.initAuth();
        }
        return result;
      } catch (error) {
        console.error(error);
        return {
          success: false,
          message: error instanceof Error ? error.message : '',
        };
      }
    };

    /**
     * sns 로그인
     * @param data
     * @returns
     */
    const loginSns = async (data: any) => {
      try {
        const result = await authApi.loginSns(data);
        if (result.success && result.access_token) {
          self.setAccessToken(result.access_token);
          self.setRefreshToken(result.refresh_token);
          self.setProp('isAuthenticated', true);
        } else {
          self.initAuth();
        }
        return result;
      } catch (error) {
        console.error(error);
        return {
          success: false,
          message: error instanceof Error ? error.message : '',
        };
      }
    };

    /**
     * 로그아웃
     */
    const logout = async (refreshToken: string) => {
      try {
        await authApi.logout(refreshToken);
        // 인증 정보 초기화
        self.initAuth();
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 사용자 정보 조회
     */
    const getUserInfo = async () => {
      try {
        const result = await authApi.getProfile();

        if (result?.userInfo) {
          self.setProp('user', result.userInfo);
          // localStorage.setItem('user', JSON.stringify(result.userInfo));
          i18next.changeLanguage(result.userInfo.langCd); // 로그인 시 계정정보의 language로 변경
          self.setProp('isAuthenticated', true);
        }

        self.setProp('isInitialized', true);
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 프로필정보 수정
     * @param data
     * @returns
     */
    const updateProfile = async (data: {
      nickNm?: string;
      profileImgPath?: string;
      uploadFile?: File;
      email?: string;
    }) => {
      try {
        const result = await authApi.updateProfile(data);

        if (result?.userInfo) {
          self.setProp('user', result.userInfo);
          // localStorage.setItem('user', JSON.stringify(result.userInfo));
        }
        return result;
      } catch (error) {
        console.error(error);
        return {
          success: false,
          message: error instanceof Error ? error.message : '',
        };
      }
    };

    /**
     * 언어정보 수정
     * @param data
     * @returns
     */
    const updateLang = async (langCd: string) => {
      const data = { refreshToken: localStorage.getItem('refreshToken') || '', langCd: langCd };
      // try {
      //   const result = await authApi.updateLang(data);

      //   if (result?.userInfo) {
      //     self.setProp('user', result.userInfo);
      //     localStorage.setItem('user', JSON.stringify(result.userInfo));
      //   }
      //   return result;
      // } catch (error) {
      //   console.error(error);
      //   return {
      //     success: false,
      //     message: error instanceof Error ? error.message : '',
      //   };
      // }
      try {
        const result = await authApi.updateLang(data);
        // accessToken이 존재하면 local storage에 저장한다.
        if (result.success && result.access_token) {
          self.setAccessToken(result.access_token);
          self.setRefreshToken(result.refresh_token);
          self.setProp('isAuthenticated', true);
        } else {
          self.initAuth();
        }
        return result;
      } catch (error) {
        console.error(error);
        return {
          success: false,
          message: error instanceof Error ? error.message : '',
        };
      }
    };

    /**
     * accessToken 갱신
     */
    const refreshAccessToken = async (refreshToken: string) => {
      try {
        const result = await authApi.refreshAccessToken(refreshToken);
        if (result.success && result.access_token && result.refresh_token) {
          self.setAccessToken(result.access_token);
          self.setRefreshToken(result.refresh_token);
        } else {
          self.initAuth();
        }
        return result;
      } catch (error) {
        console.error(error);
        return {
          success: false,
          message: error instanceof Error ? error.message : '',
        };
      }
    };

    /**
     * 회원탈퇴
     */
    const withdrawal = async (data: any) => {
      let result;
      try {
        result = await authApi.withdrawal(data);
        if (result?.data.success) {
          self.initAuth();
        }
      } catch (error) {
        console.error(error);
        result = {
          success: false,
          message: error instanceof Error ? error.message : 'Unknown error',
        };
      }
      return result;
    };

    return {
      login,
      loginSns,
      logout,
      getUserInfo,
      updateProfile,
      updateLang,
      refreshAccessToken,
      withdrawal,
    };
  });

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
