import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withSetPropAction } from '../extensions/withSetPropAction';

export enum Category {
  Secondary = 'secondary',
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

const CategoryEnum = types.enumeration<Category>('Category', Object.values(Category));

/**
 * [open]: Alert 창 표시 여부
 * [category]: Alert 창 카테고리
 * [title]: Alert 창 제목
 * [content]: Alert 창 내용
 * [hasCancelButton]: 취소 버튼 표시 여부
 * [btnLabel]: 버튼 라벨
 * [onConfirm]: 확인 버튼 클릭 시 실행할 함수
 */
export const AlertStore = types
  .model('AlertStore', {
    open: types.optional(types.boolean, false),
    category: types.optional(CategoryEnum, Category.Secondary),
    title: types.optional(types.string, ''),
    content: types.optional(types.string, ''),
    hasCancelButton: types.optional(types.boolean, false),
    btnLabel: types.optional(types.string, ''),
  })
  .volatile(() => ({
    onConfirm: () => {
      // do nothing
    },
  }))
  .actions(withSetPropAction)
  .actions((self) => ({
    setOnConfirm(func: VoidFunction) {
      self.onConfirm = func;
    },
  }))
  .actions((self) => ({
    closeAlert() {
      self.setProps({
        open: false,
        category: Category.Secondary,
        title: '',
        content: '',
        hasCancelButton: false,
        btnLabel: '',
      });
    },
    invokeOnConfirm() {
      if (self.onConfirm) {
        self.onConfirm();
        // 초기화
        self.setOnConfirm(() => {
          // do nothing
        });
      }
    },
  }));

type TAlertStore = Instance<typeof AlertStore>;
type TAlertStoreSnapshot = SnapshotOut<typeof AlertStore>;
export interface IAlertStore extends TAlertStore {}
export type TAlertStoreKeys = keyof TAlertStoreSnapshot & string;
export interface IAlertStoreSnapshot extends TAlertStoreSnapshot {}
